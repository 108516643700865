import React, { useContext } from "react"
import { tracking } from "utils/tracking"
import { ModeType, $TextFormPair } from "typings/modules"
import { SanityFiftyFiftyText } from "typings/graphql"
import { Container, CtaContainer } from "./Text.styles"
import { Button, ButtonVariant } from "components/UI/Button/Button"
import { ThemeContext, ThemeProvider } from "styled-components"
import { PortableText } from "components/UI/PortableText/PortableText"
import { EyebrowIcon } from "components/UI/EyebrowIcon"

type Props = {
  item: SanityFiftyFiftyText
} & $TextFormPair

export const Text = ({ item, $textFormPair }: Props) => {
  const themeContext = useContext(ThemeContext)
  const eyebrowIcon = item?.eyebrowIcon?.asset?.url
  const titleContent = item?._rawTitleContent
  const content = item?._rawContent
  const mode = item?.mode as ModeType
  const buttonText = item?.buttonText
  const link = item?.link
  const internalName = item?.internalName || ""
  const bgColor = item?.bgColor?.opacityHex
  return (
    <ThemeProvider theme={{ localTheme: themeContext[mode] }}>
      <Container
        $textFormPair={$textFormPair}
        $bgColor={bgColor}
        base="1 [10] 1"
        md="2 [12] 2"
        lg="2 [8] 2"
      >
        <div>
          {eyebrowIcon && <EyebrowIcon src={eyebrowIcon} alt="" />}
          {titleContent && (
            <PortableText
              blocks={titleContent}
              $mode={mode}
              keepLastElementBottomPadding
            />
          )}
          {content && <PortableText blocks={content} $mode={mode} />}
          {link && buttonText && (
            <CtaContainer>
              <Button
                to={link}
                variant={`primary-${mode}` as ButtonVariant}
                onClick={() => {
                  tracking.elementClicked(internalName, "Fifty Fifty")
                }}
              >
                {buttonText}
              </Button>
            </CtaContainer>
          )}
        </div>
      </Container>
    </ThemeProvider>
  )
}

export default Text
