import styled from "styled-components"
import { mq } from "styles"
import { Grid } from "components/UI/Grid/Grid"
import { $BgColor, $TextFormPair } from "typings/modules"

export const Container = styled(Grid)<$BgColor & $TextFormPair>`
  align-items: center;
  background: ${({ $bgColor }) => $bgColor};
  padding: ${({ $textFormPair }) =>
    $textFormPair ? "64px 0 0" : "32px 0 114px"};

  ${mq.minWidth("md")} {
    padding: ${({ $textFormPair }) =>
      $textFormPair ? "96px 0 0" : "48px 0 162px"};
  }
  ${mq.minWidth("lg")} {
    padding: 96px 0 96px;
  }
  ${mq.maxWidth("lg")} {
    .swiper-container & {
      padding: 48px 0 128px;
    }
  }
  ${mq.maxWidth("md")} {
    .swiper-container & {
      padding: 32px 0 96px;
    }
  }
`

export const CtaContainer = styled.div`
  margin-top: 32px;
  display: block;
`
